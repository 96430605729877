#countdown {
  width: 250px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  /* text-align: center; */
  margin: auto;
  justify-content: center;
  background: transparent !important;
}

#countdown .box {
  padding: 0 !important;
  border-right: none !important;
  margin: 0 1rem;
  background: rgba(43, 42, 42, 0.7);
}

#countdown .col-4:last-child .box {
  border-right-color: transparent;
}

#countdown .box p {
  font-size: 25px;
  margin: 0;
  color: var(--orange-color) !important;
}

#countdown .box .text {
  font-size: 16px;
  font-family: var(--font-family);
  color: var(--orange-color) !important;
  font-weight: 400;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }

  h1 {
    font-size: 58px;
  }

  #countdown {
    width: 350px;
    padding: 1.5rem 0 0 !important;
    margin-top: -2rem;
    margin-left: 5rem;
  }
}
